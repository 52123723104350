<template>
  <div class="popup_sub_container">
    <h2>Change Booking Cost {{ item.airway_bill ? ` - ${item.airway_bill}` : '' }}</h2>
    <i class="closeme" @click="closeModal">X</i>
    <div class="jobs_table sp">
      <div class>
        <section>
          <div class="form_section1">
            <table>
              <tr>
                <td>
                  <div class="label_and_element_wrapper">
                    <label>
                      Invoice Value
                    </label>
                    <input type="text" :value="item.invoice_upload.invoice_value" disabled />
                  </div>
                </td>
                <td>
                  <div class="label_and_element_wrapper">
                    <label>
                      Initial Value
                    </label>
                    <input type="text" :value="item.invoice_upload.booking_value" disabled />
                  </div>
                </td>
                <td>
                  <div class="label_and_element_wrapper">
                    <label>
                      Total Cost (With Markup)
                    </label>
                    <input type="text" :value="item.cost" disabled />
                  </div>
                </td>
                <td>
                  <div class="label_and_element_wrapper">
                    <label>
                      Recon Status
                    </label>
                    <input type="text" :value="generateReconStatus()" disabled />
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div class="form_section1">
            <table>
              <tr>
                <td>
                  <div class="label_and_element_wrapper">
                    <label>
                      New Booking value
                    </label>
                    <input type="number" v-model="data.booking_cost" @input="calculateMarkup" />
                  </div>
                </td>
                <td>
                  <div class="label_and_element_wrapper">
                    <label>
                      New Total Cost (with Markup) 
                    </label>
                    <input type="number" v-model="data.booking_total_cost" disabled />
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </section>

        <section class="centered">
          <a href="javascript:void(0)" class="link_bt bt_cancel" @click="closeModal">CANCEL</a>
          <a href="javascript:void(0)" class="link_bt bt_save" @click="update">UPDATE</a>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['item'],
  data() {
    return {
      data: {
        booking_cost:0,
        booking_ref:null,
        booking_total_cost:0
      },
      booking_id:null,
    }
  },
  created() {
    this.booking_id = this.item.booking_id;
    this.data.booking_ref = this.item.airway_bill;
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    async update() {

      if(!this.booking_id) {

        this.toast.error('Booking id not found');

        return;
      }

      if(this.data.booking_cost < 1) {

        this.toast.error('New booking cost must be greater than 0');

        return;
      }

      try {
        const response = await this.axios.put("/api/invoiceupload/booking-cost-update/" + this.booking_id, this.data)

        this.toast.success(response.data.msg);

        this.closeModal();
      } catch (e) {
        console.log("error", e);
      }
    },
    generateReconStatus() {
      if(this.item.invoice_upload.invoice_value == this.item.invoice_upload.booking_value) {
        return 'Amounts are Equal';
      }

      if(this.item.invoice_upload.invoice_value > this.item.invoice_upload.booking_value) {
        return 'Inv Amt > Booking Amt';
      }

      if(this.item.invoice_upload.invoice_value < this.item.invoice_upload.booking_value) {
        return 'Inv Amt < Booking Amt';
      }
    },
    calculateMarkup() {

      let cost = 0;

      if (this.item.carrier_code === 'STC') {

        let handlingFeePercentage = 0;

        if (this.item.client === 'kwm') {
          handlingFeePercentage = this.item.handling_fee_percentage || 20;
        }

        let priceWithGST = this.data.booking_cost;
        let priceWithoutGST = priceWithGST - (priceWithGST / 11);

        let price_without_GST = priceWithoutGST.toFixed(2);
        let handling_fee = (priceWithoutGST * handlingFeePercentage / 100).toFixed(2);

        let total_price = (parseFloat(price_without_GST) + parseFloat(handling_fee)).toFixed(2);
        let gst_value = (total_price * 10 / 100).toFixed(2);

        let totalPriceWithGST = (parseFloat(total_price) + parseFloat(gst_value)).toFixed(2);

        let total_price_with_gst = totalPriceWithGST;

        cost = totalPriceWithGST;

      } else {
        cost = this.data.booking_cost;
      }


      this.data.booking_total_cost = cost;
    }
  }
}
</script>
<style lang="scss" scoped>
.popup_sub_container {
  padding: 20px;
  width: 100%;
  margin: auto;

  background: #fff;
}

.popup_sub_container>section {
  padding: 20px 0 0 0;
}

h3.sub_head {
  font-size: 20px;
  font-weight: 600;
  padding: 0;
}

.preferred_hide {
  display: none;
}

.chk_prefered {
  width: 20px;
  height: 20px;
  display: inline-block;
  margin: 0 20px;
}

.chk_prefered:checked+hr+.preferred_hide {
  display: block;
}

.title_image {
  display: block;
  margin: auto;
}

.form_section1 {
  width: 100%;
  height: auto;
  padding: 20px;
  background: #ececec;
  margin: 20px 0;
}

i.closeme {
  width: 30px;
  height: 30px;
  background: #000;
  color: #06A5ED;
  font-size: 14px;
  text-align: center;
  line-height: 30px;
  border-radius: 100px;
  display: block;
  font-style: normal;
  font-weight: bold;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}

.jobs_table table {
  border: 0;
}

.jobs_table table tr td {
  padding: 0;
  border: 0;
}

.label_and_element_wrapper input[type="date"] {
  display: block;
  text-align: left;
  font-size: 14px;
  padding: 5px 20px;
  width: 100%;
  height: 40px;
  background: #fff;
  border: 1px solid #ccc;
}
</style>